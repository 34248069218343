import { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { translate } from '@app/locale/translate'
import { getProfileList } from '@app/components/utils'
import { locales } from '@app/locale/utils'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { updateUserInfo, logout } from '@app/store'
import { fetchUserData } from '@app/store'

import { Flex, Box, Text, Heading } from 'rebass'
import { Select } from '@rebass/forms'
import Theme from '@app/styles/theme'
import Popup from "reactjs-popup"
import { push as Menu } from 'react-burger-menu'
import { FaUserCircle, FaFacebook, FaInstagram, FaYoutube, FaTwitter, FaRandom } from 'react-icons/fa'


const Header = ({ locale, user, city, logout, updateUserInfo, fetchUserData }) => {
  const router = useRouter()
  const [profiles, setProfiles] = useState([])
  const [currProfile, setCurrProfile] = useState(null)
  const [unread, setUnread] = useState(0)

  const [selectedLocale, setSelectedLocale] = useState(locale)

  const updateLocale = (e) => {
    const targetValue = e.target.value
    // console.log('update locale to: ', targetValue)
    setSelectedLocale(targetValue)
    router.replace({
      pathname: router.pathname,
      query: { ...router.query, locale: targetValue }
    }, router.asPath)
  }

  const menuStyles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '30px',
      height: '20px',
      right: '12px',
      top: '20px'
    },
    bmBurgerBars: {
      background: Theme.colors.darkGray,
    },
    bmBurgerBarsHover: {
      background: Theme.colors.primary
    },
    bmCrossButton: {
      height: '24px',
      width: '24px'
    },
    bmCross: {
      background: Theme.colors.lightGray,
    },
    bmMenuWrap: {
      position: 'fixed',
      height: '100%'
    },
    bmMenu: {
      background: Theme.colors.white,
      padding: '0 0 0 0'
    },
    bmMorphShape: {
      fill: Theme.colors.white
    },
    bmOverlay: {
      background: 'rgba(0, 0, 0, 0.3)'
    }
  }
  const fetchUnread = (type, id) => {
    fetchUserData(user, {
      href: `/api/convos/${type}/${id}/unread-count`,
      method: 'GET'
    }, {}, (data) => {
      setUnread(data.count);
      //console.log('unread', data.count);
    }, (err) => {
      console.error(err);
    })
  }

  useEffect(() => {
    if (user) {
      const ps = getProfileList(user, true).sort((a, b) => {
        if (a.id === 'admin') return -1
        if (b.id === 'admin') return 1
        return a.name.localeCompare(b.name, 'en', { sensitivity: 'base' })
      })
      // console.log(ps)
      setProfiles(ps)
      if (ps.filter(p => p.id === user.curr).length === 0) {
        setCurrProfile(null)
      }
      else {
        setCurrProfile(user.curr)

        const profileType = ps.filter(p => p.id === user.curr)[0].type;
        if(profileType !== "venue" && profileType !== "artist") {
          return;
        }

        fetchUnread(profileType, user.curr)
        const interval = setInterval(() => {
          fetchUnread(profileType, user.curr)
        }, 25*1000) // in ms, so every 25s
        return () => clearInterval(interval);
      }
    }
  }, [user])


  const switchProfile = (pid) => {
    // console.log('switch profile to: ', pid)
    updateUserInfo({ ...user, curr: pid })
    router.push('/dashboard')
  }

  const getProfileFrom = (pid) => {
    if (profiles.length > 0 && pid) {
      return profiles.filter(p => p.id === pid)[0]
    }
    return {}
  }

  const getSearchMenuName = () => {
    if (currProfile) {
      const p = getProfileFrom(currProfile)
      if (currProfile === 'admin')
        return translate('Search')
      if (user && user.city.festival.selfmatch_only) {
        return null
      }
      if (p.type === 'artist')
        return translate('Find Venues')
      else if (p.is_self_match) {
        return null
      }
      return translate('Find Performers')
    }
    return null
  }

  const getSearchMenuPath = () => {
    if (currProfile) {
      if (currProfile === 'admin')
        return '/search'
      if (getProfileFrom(currProfile).type === 'venue')
        return '/find/artist'
      return '/find/venue'
    }
    return ''
  }

  const getProfileIcon = (pid) => {
    const profile = getProfileFrom(pid)
    let imageURL
    if (profile && profile.image_url) {
      imageURL = profile.image_url
    }
    if (profile && profile.type === 'venue') {
      if (profile.latitude && profile.longitude) {
        imageURL = '/images/location.png'
      }
      else {
        imageURL = '/images/icon_live.png'
      }
    }
    if (imageURL) {
      return (
        <Box
          width='36px'
          height='36px'
          sx={{
            background: 'url(' + imageURL + ') no-repeat center center',
            backgroundSize: 'cover',
            borderRadius: '100%',
            border: '2px solid ' + Theme.colors.accent
          }}
        />
      )
    }
    return (
      <FaUserCircle fontSize='32px' color={'darkGray'} />
    )
  }

  const menuItemStyle = {
    display: ['block', 'inline-block', 'inline-block'],
    py: [3, 0, 0],
    mx: [0, 2, 2],
    fontSize: [3, 1, 2],
    textAlign: 'center',
    borderTop: ['1px solid lightGray', 'none', 'none']
  }

  const getFooterItems = (city) => {
    return (
      <Box display={['block', 'none', 'none']}>
        <Text
          as="a"
          href={city && city.contact_us_url ? city.contact_us_url : "https://www.makemusicday.org/contact/"}
          target='blank'
          sx={menuItemStyle}
        >{translate('Contact Us')}</Text>
        <Flex
          py={2}
          fontSize={3}
          sx={{
            textAlign: 'center',
            borderTop: '1px solid lightGray'
          }}
          justifyContent='space-around'
        >
          <Select
            id='locale'
            name='locale'
            value={selectedLocale}
            onChange={updateLocale}
            py={1}
            sx={{
              width: 'auto',
              paddingRight: 27,
              border: 'none'
            }}
          >
            {
              locales.map( l => (
                <option
                  key={l.code}
                  value={l.code}
                >
                  {l.name}
                </option>
              ))
            }
          </Select>
        </Flex>
        <Flex
          sx={menuItemStyle}
          justifyContent='space-around'
        >
          {
            city && city.facebook_url &&
            <Text
              as="a"
              href={city.facebook_url}
              title={translate("Visit Facebook")}
              target="blank"
              fontSize='21px'
            ><FaFacebook color={'darkGray'} /></Text>
          }
          {
            city && city.instagram_url &&
            <Text
              as="a"
              href={city.instagram_url}
              title={translate("Visit Instagram")}
              target="blank"
              fontSize='21px'
            ><FaInstagram color={'darkGray'} /></Text>
          }
          {
            city && city.youtube_url &&
            <Text
              as="a"
              href={city.youtube_url}
              title={translate("Visit YouTube")}
              target="blank"
              fontSize='21px'
            ><FaYoutube color={'darkGray'} /></Text>
          }
          {
            city && city.twitter_url &&
            <Text
              as="a"
              href={city.twitter_url}
              title={translate("Visit Twitter")}
              target="blank"
              fontSize='21px'
            ><FaTwitter color={'darkGray'} /></Text>
          }
        </Flex>
      </Box>
    )
  }

  const getMenuItems = () => {
    if (user && user.token.expires_at > Date.now()) { // logged in
      return (
        <Flex
          alignItems='center'
          justifyContent="space-between"
          flexGrow={1}
          flexDirection={['column', 'row', 'row']}
          sx={{
            borderBottom: ['1px solid lightGray', 'none', 'none'],
            a: {
              borderLeft: ['5px solid transparent', 'none', 'none']
            },
            '.highlight': {
              textDecoration: ['none', 'underline', 'underline'],
              borderLeft: ['5px solid ' + Theme.colors.accent, 'none', 'none']
            }
          }}
        >
          {/* MOBILE */}
          <Box display={['block', 'none', 'none']} minHeight="45px">
            {currProfile &&
              <Flex p={3}>
                {getProfileIcon(currProfile)}
                <Box ml={2}>
                  <Text fontSize={11} mb={1}>{translate('Active as:')}</Text>
                  <Text fontSize={13} fontWeight="bold" sx={{
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    maxWidth: '120px'
                  }}>{getProfileFrom(currProfile).name}</Text>
                </Box>
              </Flex>
            }
            {profiles.filter(p => p.id !== currProfile).length > 0 && <Box mb={3}>
              <Text ml={3} fontSize={13} fontWeight="bold">{translate('Switch to')}</Text>
              {profiles.filter(p => p.id !== currProfile).map((p, i) => {
                return (
                  <Flex key={i} height={32} alignItems='center' px={4} sx={{ cursor: 'pointer' }} onClick={() => { switchProfile(p.id) }}>
                    <Box
                      width='15px'
                      height='15px'
                      minWidth='15px'
                    >
                      <FaRandom
                        color={'darkGray'}
                        fontSize={15}
                      />
                    </Box>
                    <Text pl={2} fontSize={15} color={'darkGray'} sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '130px'
                    }}>{p.name}</Text>
                                           {
                p.unread_convo_count > 0 && <Box as="span" sx={{
                  width: '18px',
                  height: '18px',
                  textAlign: 'center',
                  background: Theme.colors.rustyOrange,
                  borderRadius: '100%',
                  border: `2px solid ${Theme.colors.white}`,
                  color: Theme.colors.white,
                  fontSize: "10px",
                  lineHeight: '14px'
                }}>{p.unread_convo_count}</Box>
              }
                  </Flex>
                )
              })}
            </Box>}
          </Box>
          <Box>
            {!currProfile && <Link href='/welcome'>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/welcome' ? 'highlight' : ''}
              >{translate('Home')}</Text>
            </Link>}
            {currProfile && <Link href='/dashboard'>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/dashboard' ? 'highlight' : ''}
              >{translate('Dashboard')}</Text>
            </Link>}
            {currProfile && currProfile === 'admin' && <Link href='/performances'>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/performances' ? 'highlight' : ''}
              >{translate('Performances')}</Text>
            </Link>}
            {getSearchMenuName() && <Link href={getSearchMenuPath()}>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === getSearchMenuPath() ? 'highlight' : ''}
              >{getSearchMenuName()}</Text>
            </Link>}
            {currProfile && currProfile !== 'admin' && <Link href={'/event/' + getProfileFrom(currProfile).type + '/create'}>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/event/' + getProfileFrom(currProfile).type + '/create' ? 'highlight' : ''}
              >{translate('Create Event')}</Text>
            </Link>}
            {currProfile && currProfile !== 'admin' && <Link href='/inbox'>
              <Text
                as="a"
                sx={{
                  ...menuItemStyle,
                  position: 'relative'
                }}
                className={router.pathname === '/inbox' ? 'highlight' : ''}
              >{translate('Inbox')}
              {
                unread !== 0 && <Box as="span" sx={{
                  position: 'absolute',
                  width: '18px',
                  height: '18px',
                  top: '-6px',
                  right: '-15px',
                  background: Theme.colors.rustyOrange,
                  borderRadius: '100%',
                  border: `2px solid ${Theme.colors.white}`,
                  color: Theme.colors.white,
                  fontSize: "10px",
                  lineHeight: '14px'
                }}>{unread}</Box>
              }
              </Text>
            </Link>}
            <Link href='/profiles'>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/profiles' ? 'highlight' : ''}
              >{translate('My Profiles')}</Text>
            </Link>
            {
              currProfile === 'admin' &&
              <Link href='/admin'>
                <Text
                  as="a"
                  sx={menuItemStyle}
                  className={router.pathname === '/admin' ? 'highlight' : ''}
                >{translate('Setup')}</Text>
              </Link>
            }
            {
              currProfile === 'admin' && getProfileFrom('admin').type === 'super' &&
              <Link href='/system'>
                <Text
                  as="a"
                  sx={menuItemStyle}
                  className={router.pathname === '/system' ? 'highlight' : ''}
                >{translate('System')}</Text>
              </Link>
            }
          </Box>
          {/* DESKTOP */}
          <Box display={['none', 'block', 'block']}>
            <Flex>
              {currProfile && <Box mr={2}>
                <Text fontSize={11} mb={1}>{translate('Active as:')}</Text>
                <Text fontSize={13} fontWeight="bold" sx={{
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  maxWidth: '120px'
                }}>{getProfileFrom(currProfile).name}</Text>
              </Box>}
              <Popup
                trigger={
                  <Flex alignItems="center">
                    {getProfileIcon(currProfile)}
                  </Flex>
                }
                position="bottom right"
              >
                <Box
                  maxHeight={'calc(100vh - 128px)'}
                  overflowY={'scroll'}
                >
                  {profiles.filter(p => p.id !== currProfile).length > 0 && <Box
                    pb={2}
                    mb={2}
                    sx={{
                      borderBottom: '1px solid lightGray'
                    }}
                  >
                    <Flex height={32} alignItems='center' >
                      <Text ml={2} fontSize={13} fontWeight="bold">{translate('Switch to')}</Text>
                    </Flex>
                    {profiles.filter(p => p.id !== currProfile).map((p, i) => {
                      return (
                        <Flex
                          key={i}
                          height={32}
                          alignItems='center'
                          px={3}
                          sx={{
                            cursor: 'pointer',
                            ':hover': {
                              backgroundColor: 'neutral'
                            }
                          }}
                          onClick={() => { switchProfile(p.id) }}
                        >
                          <Box
                            width='15px'
                            height='15px'
                            minWidth='15px'
                          >
                            <FaRandom
                              color={'darkGray'}
                              fontSize={15}
                            />
                          </Box>
                          <Text pl={2} fontSize={15} color={'darkGray'} sx={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                           width: '130px'
                          }}>{p.name}</Text>
                                           {
                p.unread_convo_count > 0 && <Box as="span" sx={{
                  width: '18px',
                  height: '18px',
                  textAlign: 'center',
                  background: Theme.colors.rustyOrange,
                  borderRadius: '100%',
                  border: `2px solid ${Theme.colors.white}`,
                  color: Theme.colors.white,
                  fontSize: "10px",
                  lineHeight: '14px'
                }}>{p.unread_convo_count}</Box>
              }
                        </Flex>
                      )
                    })}
                  </Box>}
                  <Flex height={32} alignItems='center'>
                    <Link href="/account">
                      <a className="link">{translate('Account')}</a>
                    </Link>
                  </Flex>
                  <Flex height={32} alignItems='center'>
                    <a className="link" onClick={() => { logout(); router.push('/') }}>{translate('Logout')}</a>
                  </Flex>
                </Box>
              </Popup>
            </Flex>
          </Box>
          {/* MOBILE */}
          <Box display={['block', 'none', 'none']}>
            <Link href='/account'>
              <Text
                as="a"
                sx={menuItemStyle}
                className={router.pathname === '/account' ? 'highlight' : ''}
              >{translate('Account')}</Text>
            </Link>
            <Text
              as="a"
              sx={menuItemStyle}
              onClick={() => { logout(); router.push('/') }}
            >{translate('Logout')}</Text>
          </Box>
          {getFooterItems(currCity)}
        </Flex>
      )
    }
    return (
      <Flex
        alignItems='center'
        justifyContent="flex-end"
        flexGrow={1}
        flexDirection={['column', 'row', 'row']}
        sx={{
          a: {
            borderLeft: ['5px solid transparent', 'none', 'none']
          },
          '.highlight': {
            textDecoration: ['none', 'underline', 'underline'],
            borderLeft: ['5px solid ' + Theme.colors.accent, 'none', 'none']
          }
        }}
      >
        {city && !city.is_paused && city.festival.is_launched && <Link href="/account/create">
          <Text
            as="a"
            sx={menuItemStyle}
            className={router.pathname === '/account/create' ? 'highlight' : ''}
          >{translate('Create Account')}</Text>
        </Link>}
        {city && !city.is_paused &&  <Link href="/account/login">
          <Text
            as="a"
            sx={menuItemStyle}
            className={router.pathname === '/account/login' ? 'highlight' : ''}
          >{translate('Login')}</Text>
        </Link>}
        {getFooterItems(currCity)}
      </Flex>
    )
  }

  const currCity = (user && user.city) ? user.city : city

  return (
    <Box
      bg='neutral'
      width={1}
      height={[60, 72, 72]}
      sx={{
        position: 'fixed',
        top: 0,
        zIndex: 101
      }}
    >
      {/* MOBILE */}
      <Box
        display={['block', 'none', 'none']}
      >
        <Menu
          // isOpen
          right
          pageWrapId={"page-content"}
          outerContainerId={"page-container"}
          styles={menuStyles}
        >
          {getMenuItems()}
        </Menu>
        <a href={currCity ? currCity.url : '/'} target="_blank" style={{ margin: 0 }}>
          <img width="60" height="60" src={currCity ? currCity.logo_url : '/images/makemusic_logo_bw.png'}></img>
        </a>
        <Heading
          fontSize={3}
          px={8}
          py={18}
          color='darkGray'
          sx={{
            position: 'absolute',
            left: 60,
            top: 0,
            textTransform: 'capitalize'
          }}
        >
          {translate('Make Music Day')}
        </Heading>
      </Box>
      {/* DESKTOP */}
      <Box
        display={['none', 'block', 'block']}
        px={[8, 16, 32]}
      >
        <Flex>
          <Box
            mr={24}
          >
            <a href={currCity ? currCity.url : '/'} target="_blank">
              <img width="72" height="72" src={currCity ? currCity.logo_url : '/images/makemusic_logo_bw.png'}></img>
            </a>
          </Box>
          {getMenuItems()}
        </Flex>
      </Box>
    </Box>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  city: state.city,
  locale: state.locale
})

const mapDispatchToProps = dispatch => ({
  fetchUserData: bindActionCreators(fetchUserData, dispatch),
  updateUserInfo: bindActionCreators(updateUserInfo, dispatch),
  logout: bindActionCreators(logout, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
