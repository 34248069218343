import React from 'react'
import { FaFacebook, FaInstagram, FaYoutube, FaTwitter } from 'react-icons/fa'
import { useRouter } from 'next/router'
import { translate } from '@app/locale/translate'
import { locales } from '@app/locale/utils'

import { connect } from 'react-redux'
// import dayjs from 'dayjs'

import { Flex, Box } from 'rebass'
import { Select } from '@rebass/forms'
import Theme from '@app/styles/theme'

const Footer = ({ user, city, locale }) => {
  const router = useRouter()

  const currCity = user && user.city ? user.city : city

  const [selectedLocale, setSelectedLocale] = React.useState(locale)

  const updateLocale = (e) => {
    const targetValue = e.target.value
    // console.log('update locale to: ', targetValue)
    setSelectedLocale(targetValue)
    router.replace({
      pathname: router.pathname,
      query: { ...router.query, locale: targetValue }
    }, router.asPath)
  }

  return (
    <Box
      bg={Theme.colors.gray}
      width={1}
      height={48}
      display={['none', 'block', 'block']}
      color={Theme.colors.white}
      px={[8, 16, 32]}
      sx={{
        position: 'fixed',
        bottom: 0
      }}
    >
      <Flex
        justifyContent='space-between'
        alignItems='center'
        height="100%"
      >
        <Box>
          {
            currCity && currCity.contact_us_url &&
            <a href={currCity.contact_us_url} target='blank' className='invert'>
              {translate('Contact Us')}
            </a>
          }
        </Box>

        <Flex
          flexGrow={1}
          justifyContent='flex-end'
        >
          <Select
            id='locale'
            name='locale'
            fontSize={14}
            py={1}
            mr={3}
            value={selectedLocale}
            onChange={updateLocale}
            bg={Theme.colors.gray}
            color={Theme.colors.white}
            sx={{
              width: 'auto',
              paddingRight: 24
            }}
          >
            {
              locales.map( l => (
                <option
                  key={l.code}
                  value={l.code}
                >
                  {l.name}
                </option>
              ))
            }
          </Select>
        </Flex>
        <Box height={24}>
          {
            currCity && currCity.facebook_url &&
            <a className="icon" href={currCity.facebook_url} title={translate("Facebook")} target="blank">
              <FaFacebook color={Theme.colors.white} />
            </a>
          }
          {
            currCity && currCity.instagram_url &&
            <a className="icon" href={currCity.instagram_url} title={translate("Instagram")} target="blank">
              <FaInstagram color={Theme.colors.white} />
            </a>
          }
          {
            currCity && currCity.youtube_url &&
            <a className="icon" href={currCity.youtube_url} title={translate("YouTube")} target="blank">
              <FaYoutube color={Theme.colors.white} />
            </a>
          }
          {
            currCity && currCity.twitter_url &&
            <a className="icon" href={currCity.twitter_url} title={translate("Twitter")} target="blank">
              <FaTwitter color={Theme.colors.white} />
            </a>
          }
        </Box>
      </Flex>
    </Box>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  city: state.city,
  locale: state.locale
})
export default connect(mapStateToProps)(Footer)
