import React from 'react'
import { useRouter } from 'next/router'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setLocale, editAccount } from '@app/store'

import Header from './header'
import Footer from './footer'

import dayjs from 'dayjs'
import { Box } from 'rebass'
import Theme from '@app/styles/theme'
import { isLocale } from '../locale/utils'

const Template = (props) => {

  const router = useRouter()
  dayjs.locale(props.locale.replace('_','-'))

  React.useEffect(() => {
    const localeQuery = router.query.locale
    // console.log('check locale on template', localeQuery, props.locale)
    if (localeQuery && localeQuery !== props.locale && isLocale(localeQuery)) {
      props.setLocale(localeQuery)
      dayjs.locale(localeQuery.replace('_','-'))
      if(props.user) {
        props.editAccount(props.user, {
          locale: localeQuery
        })
      }
    }

  }, [router.query.locale])

  return (
    <Box
      id="page-container"
      pt={72}
      pb={48}
      minHeight={'100vh'}
      sx={{
        fontFamily: Theme.fonts.body
      }}
    >
      <Header />
      <Box
        id="page-content"
        px={[8, 16, 32]}
        py={[16, 32, 48]}
        sx={{
          transform: ['auto', 'none !important', 'none !important']
        }}
      >
        {props.children}
      </Box>
      <Footer />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user,
  locale: state.locale
})

const mapDispatchToProps = dispatch => ({
  setLocale: bindActionCreators(setLocale, dispatch),
  editAccount: bindActionCreators(editAccount, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Template)
